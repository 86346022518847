import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { GraphQLError } from "graphql";
import Rollbar from "rollbar";
import store from "../store/store";
import { setApiVersion } from "../store/actions";

import { storeLocal, fetchLocal } from "./handleLocalStorage";
import webviewBridge from "./webview-bridge";
import i18n from "es2015-i18n-tag";
import { CreateAlert, getAlertList } from "./handleAlerts";
import { alertTypes } from "src/constants/alertConstants";
import handleErrorMessage, { sendError } from "src/services/handleErrors";

type handleError = (errorObject: {
  error: GraphQLError | { message: string; name: string };
  displayErrorAlert?: boolean;
  disableQueries?: boolean;
  isMaintenance?: boolean;
}) => void;

const setAlert = (alertMessage: string) => {
  const currentAlerts = getAlertList();
  // Avoid same repetitive alert messages
  if (!currentAlerts.includes(alertMessage)) {
    CreateAlert({ alertMessage, alertType: alertTypes.error });
  }
};

const errorLink = (handleErrors: handleError, rollbar: Rollbar) =>
  onError(e => {
    // Error codes: Trac #4348
    // displayErrorAlert: true => Show the user the alert with an error message from API
    // displayErrorAlert: false => Redirect user to the general error page
    if (e.graphQLErrors) {
      // Handle only general errors here. Every component will handle the GraphQL response errors by itself
      // TrackJS.addMetadata("currentObjectId", fetchLocal("currentObjectId") || "");
      // TrackJS.addMetadata("currentObjectType", fetchLocal("currentObjectType") || "");

      e.graphQLErrors.forEach(graphQlError => {
        sendError(rollbar, graphQlError.message || "graphQlError", graphQlError, 0.25);
        // @ts-ignore
        const errorCode = graphQlError.code;
        if (errorCode === 503) {
          handleErrors({ error: graphQlError, isMaintenance: true, disableQueries: true, displayErrorAlert: true }); // display error Alert true does not send us to error Boundary
        } else if (errorCode >= 500 || (errorCode === 400 && graphQlError.message !== "Request is empty.")) {
          handleErrors({
            error: { message: i18n`Midagi läks valesti, proovi palun uuesti!`, name: "" },
            displayErrorAlert: false
          });
          setAlert(i18n`Midagi läks valesti, proovi palun uuesti!`);
        } else if (errorCode === 403) {
          // Missing permissions
          handleErrors({ error: graphQlError, displayErrorAlert: true });
          setAlert(handleErrorMessage(graphQlError));
        } else if (errorCode === 404 && graphQlError.message === "Apartment not found.") {
          storeLocal("pageRefreshError", JSON.stringify(graphQlError));
          rollbar.error("Apartment not found graphQlError", graphQlError);
          window.location.reload();
        } else if (errorCode === 404) {
          // Not found
          sendError(rollbar, "graphQlError 404", graphQlError, 0.25);
          handleErrors({ error: graphQlError, displayErrorAlert: true });
          setAlert(handleErrorMessage(graphQlError));
        } else if (errorCode === 0) {
          // Other non-handled errors, e.g. invalid form data
          console.log("errorCode:", errorCode);
          console.log("error: ", graphQlError);
          sendError(rollbar, graphQlError.message || "graphQlError, errorCode 0", graphQlError, 0.35);
          // handleErrors({ error: graphQlError, displayErrorAlert: true });  <-- Every component handles those errors by itself
        }
      });
    } else if (e.networkError) {
      sendError(rollbar, "Network error", e.networkError, 0.1);
      setAlert(i18n`Midagi läks valesti, proovi palun uuesti!`);
      // If Native then dont send to /public
      if (!webviewBridge.isNative()) {
        // If user manages to get here, it means something is wrong with connection to server and we cant handle this. But we can allow the use of /public
        handleErrors({ disableQueries: true, displayErrorAlert: false, error: { message: "", name: "" } });
      }
    } else {
      // tslint:disable-next-line:no-unused-expression
      setAlert(i18n`Midagi läks valesti, proovi palun uuesti!`);
      rollbar.error("General GraphQL error", e);
    }
  });

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include"
});

const middlewareLink = (token?: string) =>
  new ApolloLink((operation, forward) => {
    // sets the accept-language header dynamically for every request based on chosen locale
    const authToken = token ? "Bearer " + token : "";

    const locale = fetchLocal("locale", process.env.REACT_APP_DEFAULT_LOCALE);
    const header = token
      ? {
          "Accept-Language": locale,
          Authorization: authToken
        }
      : { "Accept-Language": locale };

    operation.setContext({
      headers: header
    });

    if (forward) {
      return forward(operation);
    }

    return null;
  });

const headerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const responseHeaders = context.response.headers; // Capture headers

    // Get current API version from headers
    const newApiVersion = responseHeaders.get("X-API-Version");
    // Get current API version from store
    const currentApiVersion = store.getState().general.apiVersion;

    if (newApiVersion !== currentApiVersion) {
      store.dispatch(setApiVersion(newApiVersion));
    }
    return response;
  });
});

const link = (handleErrors: handleError, rollbar: Rollbar, token?: string) =>
  ApolloLink.from([errorLink(handleErrors, rollbar), middlewareLink(token), headerLink, httpLink]);

const client = (handleErrors: handleError, rollbar: Rollbar, token?: string) =>
  new ApolloClient({
    link: ApolloLink.from([link(handleErrors, rollbar, token)]),
    cache: new InMemoryCache()
  });

export default client;

import { fetchLocal, storeLocal, isLocalStorageAvailable } from "./handleLocalStorage";
import { sleep } from "src/services/sleep";
import isFirefox from "src/services/isFirefox";
import store from "src/store/store";
import { setGlobalLoader } from "src/store/actions";

// Check if the API version has changed and make a hard refresh to reload Korto FE application
export const handleAppVersionChange = async (newVersion: string) => {
  if (!newVersion || newVersion === "" || newVersion === "0" || !isLocalStorageAvailable()) {
    return;
  }

  const currentVersion = fetchLocal("ApiVersion", "-1");

  // If the localstorage is not available or the version is the same. Otherwise make a hard refresh
  if (currentVersion !== newVersion) {
    storeLocal("ApiVersion", newVersion);
    storeLocal("LastApiVersionUpdate", new Date().getTime().toString());

    // Firefox needs to finish all the requests before reloading the page
    if (isFirefox()) {
      store.dispatch(setGlobalLoader(true));
      await sleep(2000);
    }

    // @ts-ignore
    window.location.reload(true);
  }
};

import { SET_API_VERSION, SET_GLOBAL_LOADER } from "../actions/actionTypes";

interface InitialState {
  apiVersion: string;
  globalLoader: boolean;
}

const initialState: InitialState = {
  apiVersion: "",
  globalLoader: false
};

interface Action {
  type: string;
  payload: {
    [key: string]: any;
  };
}

export default function general(state = initialState, action: Action) {
  switch (action.type) {
    case SET_API_VERSION: {
      return {
        ...state,
        apiVersion: action.payload.apiVersion
      };
    }
    case SET_GLOBAL_LOADER: {
      return {
        ...state,
        globalLoader: action.payload.globalLoader
      };
    }
    default:
      return state;
  }
}

import { Query } from "react-apollo";
import React, { useEffect } from "react";
import { i18nConfig } from "es2015-i18n-tag";
import Loader from "src/components/loader/Loader";
import { fetchLocal } from "src/services/handleLocalStorage";
import translationsQuery from "src/queries/translationsQuery";
import { defaultLocale } from "src/constants/globalConstants";
import { useSelector } from "react-redux";
import { AppState as appState } from "src/store/reducers";
import { handleAppVersionChange } from "src/services/handleAppVersionChange";
interface QueryResponses {
  translations: {
    locales: string;
    translations: {
      [name: string]: string;
    };
    number: {};
  };
}

interface Props {
  disableQueries: boolean;
}

interface BackendTranslations {
  [key: string]: string;
}

// Korto homepage translations (not received over API)
const publicViewTranslations = {
  en: {
    publicView: require("../../views/public/translations/en.public.json")
  },
  ru: {
    publicView: require("../../views/public/translations/ru.public.json")
  },
  lv: {
    publicView: require("../../views/public/translations/lv.public.json")
  },
  lt: {
    publicView: require("../../views/public/translations/lt.public.json")
  },
  et: { publicView: {} } // Use translation keys as a fallback
};

const TranslationProvider: React.FunctionComponent<Props> = props => {
  const localeFromRedux = useSelector((state: appState) => state.locale.locale);
  const { apiVersion, globalLoader } = useSelector((state: appState) => state.general);

  useEffect(() => {
    handleAppVersionChange(apiVersion);
  }, [apiVersion]);

  const locale =
    fetchLocal("locale", process.env.REACT_APP_DEFAULT_LOCALE) ||
    localeFromRedux ||
    process.env.REACT_APP_DEFAULT_LOCALE ||
    defaultLocale;

  const defaultLanguage = {
    locales: locale,
    translations: publicViewTranslations[locale]
  };

  // If server is not responding use only public view translations
  if (props.disableQueries) {
    i18nConfig(defaultLanguage);
  }

  if (globalLoader) {
    return <Loader />;
  }

  return !props.disableQueries ? (
    <Query<QueryResponses> query={translationsQuery}>
      {({ loading, data }) => {
        if (!data || loading) {
          return <Loader />;
        }

        let backendTranslations: BackendTranslations = {};

        if (!data.translations) {
          i18nConfig(defaultLanguage);
        } else {
          const { locales, translations, number } = data.translations;

          // @ts-ignore
          if (translations) {
            i18nConfig({
              locales,
              translations: { ...translations, ...defaultLanguage.translations },
              number
            });
            backendTranslations = translations;
          } else {
            i18nConfig(defaultLanguage);
          }
        }
        // Render children if not loading any more
        // Key is the translation of "Apartment" to enforce the component to rerender if backend translations are updated
        return (
          <div id="TranslationProvider" key={backendTranslations.Apartment || locale}>
            {props.children}
          </div>
        );
      }}
    </Query>
  ) : (
    <div id="TranslationProvider" key={locale}>
      {props.children}
    </div>
  );
};

export default TranslationProvider;
